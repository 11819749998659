.navbar-container {
    position: sticky;
    top: 1px;

    height: fit-content;

    z-index: 100;

    margin: 0px 1px 30px 1px;
    padding: 5px;

    font-family: "Bahnschrift";
    font-size: 1.5em;
    color: white;

    border-radius: 15px;

    transition-property: margin position;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
}
.navbar-container::before {
    content: "";

    position: absolute;
    top: -1px;
    left: -1px;

    width: 100%;
    height: 100%;

    z-index: -1;

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    background-color: rgba(0, 0, 0, 0.2);

    border: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.3);

    border-radius: 0px;

    transition-property: border-radius;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
}

.navbar-container.floating {
    margin: 15px;
    top: 15px;
}
.navbar-container.floating::before {
    border-radius: 15px;
}