@font-face {
    font-family: Bahnschrift;
    src: url("../fonts/Bahnschrift.ttf");
}

:root {
    --backdrop-blur: blur(10px);
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    width: auto;
}
body {
    height: 100vh;

    background-color: rgb(55, 55, 55);

    background-image: url("../media/rocky-beach.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

section.body {
    display: grid;

    font-family: "Bahnschrift";
}


.glass {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    background-color: rgba(0, 0, 0, 0.2);

    border: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.3);
}

.shadow {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
}