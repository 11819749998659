.navbar-item-container {
    display: inline-block;

    padding: 7px;
    border-radius: 10px;

    transition-property: background-color;
    transition-duration: 0.1s;
    transition-timing-function: linear;

    text-align: center;
}
.navbar-item-container:hover {
    background-color: rgb(52, 120, 246);
}

.navbar-item-container a {
    color: inherit;
    text-decoration: inherit;
}


.navbar-item-container:hover .navbar-item-dropdown-container {
    display: grid;
}
.navbar-item-dropdown-container {
    display: none;

    position: absolute;
    transform: translate(-12px, 7px);

    padding: 5px;
    border-radius: 15px;
}


.navbar-item-container .active-indicator {
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    width: 75%;
    height: 2px;

    background-color: white;

    pointer-events: none;
}