.card-container {
    display: grid;

    overflow: hidden;

    margin: 14px;

    font-family: "Bahnschrift";
    color: white
}

.card-header {
    display: grid;

    font-size: 1.5em;
    text-align: center;

    padding: 5px;

    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.card-body {
    display: grid;

    font-size: 1em;

    padding: 15px;

    background-color: rgb(55, 55, 55);

    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}